<template>
  <div v-if="getUser && getUser.id" class="work-requests">
    <div class="work-requests__nav-bar">
      <nav-bar />
    </div>
    <work-req-content />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WorkRequests",
  components: {
    WorkReqContent: () => import('@/components/template/work-requests/WorkReqContent'),
    NavBar: () => import('@/components/template/work-requests/NavBar')
  },
  data() {
    return {
      meta: null,
    }
  },
  computed: {
    ...mapGetters(['getUser']),
  }
}
</script>

<style lang="scss" scoped>
.work-requests {
  background: #F5F5F5;
  padding: 150px 50px 50px 50px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  min-height: 100vh;
  width: 100%;

  @media (max-width: 1200px) {
    padding: 100px 21px 41px 19px;
  }

  @media (max-width: 768px) {
    gap: 30px;
    flex-direction: column;
    justify-content: normal;
  }

  &__nav-bar {
    position: relative;
    max-width: 320px;
    width: 100%;

    @media (max-width: 992px) {
      max-width: 220px;
    }
  }
}
</style>
